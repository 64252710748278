// extracted by mini-css-extract-plugin
export var selector = "style-module--selector--1zXgX";
export var diamond = "style-module--diamond--2amYR";
export var diamondBuy = "style-module--diamondBuy--3HSHJ";
export var redeem = "style-module--redeem--1aJ6_";
export var redeemQr = "style-module--redeemQr--G56ZY";
export var redeemInfo = "style-module--redeemInfo--3KWkT";
export var addWallet = "style-module--addWallet--1IvXi";
export var addWalletIcon = "style-module--addWalletIcon--2rysN";
export var stakeLink = "style-module--stakeLink--GzAua";
export var stakeLinkActive = "style-module--stakeLinkActive--2QeMe";
export var removeLink = "style-module--removeLink--GpK1A";